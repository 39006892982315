import { initializeApp } from "firebase/app";
import {
  MessagePayload,
  Messaging,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
import { onUnmounted, ref, watch } from "vue";
import { BPush } from "@bpush/bpushsdk-web";
import { version } from "../../../package.json";
import store from "@/request/http/store";
import { isInWebview } from "@/utils/util";

const firebaseConfig = {
  apiKey: "AIzaSyC3zrJzvPzmIY_hZaSFHTWrrsj3vPfcxmo",
  authDomain: "baifuandroid.firebaseapp.com",
  projectId: "baifuandroid",
  storageBucket: "baifuandroid.appspot.com",
  messagingSenderId: "42574203204",
  appId: "1:42574203204:web:c83c58f18f6fbf049def11",
  measurementId: "G-SJL4LTKDDM",
};

const initFirebaseMessaging = () => {
  let messaging: Messaging = { 
    app: {
      name: 'default',
      options: {},
      automaticDataCollectionEnabled: false,
    },
  };
  try {
    // webview 不支援時(service worker), 防止 web 因存取 firebase 而崩潰
    if (!isInWebview()) {
      const firebaseApp = initializeApp(firebaseConfig);
      messaging = getMessaging(firebaseApp)
    }
  } catch (e) {
    console.error(e);
  }
  return messaging;
}

const useFCMToken = (messaging: Messaging) => {
  const token = ref<string | null>(null);
  const getFCMToken = async () => {
    try {
      const fcmToken = await getToken(messaging, {
        vapidKey:
          "BKZiL_55dmtRidH4nn1CoJy2VczKm4XWROouVLuv2nZy1xpVWo1DHhUSYHpSY0vtnSpZ8YK10h3_qdETCRcn5J8",
        serviceWorkerRegistration: await navigator.serviceWorker.register(
          `${process.env.VUE_APP_BASE_API_2}/${process.env.VUE_APP_PROJECT_PATH}/firebase/firebase-messaging-sw.js`
        ),
      });
      token.value = fcmToken;
    } catch (error) {
      console.error("Notification bPush 获取FCM令牌失败:", error);
    }
  };

  console.log("Notification getFCMToken");
  getFCMToken();

  return {
    token,
  };
};

const useFCMMessage = () => {
  const messaging = initFirebaseMessaging();
  const { token } = useFCMToken(messaging);
  const messages = ref<MessagePayload[]>([]);

  const handleMessage = (payload: any) => {
    console.log("Notification bPush onMessage", payload);
    messages.value.push(payload);
  };

  console.log("Notification useFCMMessage onMessage");
  const unsubscribe = onMessage(messaging, handleMessage);
  onUnmounted(() => {
    unsubscribe();
  });

  return {
    token,
    messages,
  };
};

export const useRegisterPush = () => {
  const appKey = process.env.VUE_APP_BPUSH_KEY ?? ""; // "dd436241299741a00dfd692de82b85b9";
  const bundleID = "com.baifu.bpushandroid";
  const { token } = useFCMMessage();

  // 监听token变化并注册到BPush
  const register = async () => {
    if (token.value != null) {
      try {
        const response = await BPush.registerToken({
          pushToken: token.value,
          appKey,
          bundleID,
          appVersion: version,
        });
        console.log("Notification bPush registerToken", response);
        store.set("device-token", token.value);
      } catch (error) {
        console.log("Notification bPush register token error", error);
      }
    }
  };

  // 监听token变化并调用register
  watch(token, () => {
    console.log("Notification bPush token", token.value);
    register();
  });

  // 监听推播消息变化
  // watch(messages.value, () => {
  //   console.log("Notification bPush firebase messages", messages.value);
  // });
};
