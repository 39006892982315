import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default /*@__PURE__*/_defineComponent({
  __name: 'OrangeButton',
  props: {
    disabled: Boolean,
    green: Boolean,
    title: String
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["as-btn-d center", {
          'as-btn-d-green': __props.green,
          'as-btn-d-disabled': __props.disabled
        }])
      }, _toDisplayString(__props.title), 3);
    };
  }
});