import { Module } from "vuex";
import { State } from "@/store/state";
import { ActivityInfo, ActivityListItem } from "@/interface";
import { useCustomRouter } from "@/router/custRouter";
import activityLogic from "@/scripts/activityLogic";
import { cloneDeep } from "lodash";
import { store } from "@/store";

export interface ActivitiesState {
  // leftActList: ActivityItem[];
  activityList: ActivityListItem[];
  allClaimActivity: string | null;
  claimSecretIds: string | null;
  ativoBoxInfo: any | null; //活跃度宝箱列表
  taskActList: any[]; //任务左侧分类
  allChallenge: string | null; //一键领取按钮
  taskData: Map<string, any> | null; //所有的任务类型数据
  dailyCanClaim: boolean | null;
  dailyCheckInStatus: string | null;
}

export const activities: Module<ActivitiesState, State> = {
  namespaced: true,
  state: {
    // leftActList: [],
    activityList: [],
    allClaimActivity: null,
    claimSecretIds: null,
    ativoBoxInfo: null,
    taskActList: [],
    allChallenge: null,
    taskData: null,
    dailyCanClaim: null,
    dailyCheckInStatus: null,
  },
  getters: {
    activities: (state) => (categoryId: string) => {
      if (categoryId == "0") return state.activityList;
      else return state.activityList.filter((v) => v.categoryId == categoryId);
    },
    allClaimActivity: (state) => state.allClaimActivity,
    claimSecretIds: (state) => state.claimSecretIds,
    // leftActList: (state) => state.leftActList,
    ativoBoxInfo: (state) => state.ativoBoxInfo,
    taskActList: (state) => state.taskActList,
    allChallenge: (state) => state.allChallenge,
    taskData: (state) => state.taskData ?? new Map(),
    dailyCheckInStatus: (state) => state.dailyCheckInStatus,
    hasRedDot: (state, getters, rootState, rootGetters) => {
      if (!rootGetters.loginSuccess) {
        return false;
      }

      const arr = getters.activities("0");
      let res = false;
      for (let i = 0; i < arr.length; i++) {
        const v = arr[i];
        if (v.canClaim) {
          res = true;
          break;
        }
      }

      return res;
    },
    hasShowVipActivity: (state, getters, rootState, rootGetters) => {
      if (!rootGetters.loginSuccess) {
        return false;
      }

      const arr = getters.activities("0");
      let res = false;
      for (let i = 0; i < arr.length; i++) {
        const v = arr[i];
        if (v.name == "VIP") {
          res = true;
          break;
        }
      }

      return res;
    },
    hasTaskDailyRedDot: (state, getters, rootState, rootGetters) => {
      if (!rootGetters.loginSuccess) {
        return false;
      }

      return state.dailyCanClaim && state.dailyCheckInStatus == "0";
    },
    hasTaskRedDot: (state, getters, rootState, rootGetters) => {
      if (!rootGetters.loginSuccess) {
        return false;
      }

      let res = false;
      for (let i = 0; i < state.taskActList.length; i++) {
        const element = state.taskActList[i];
        if (element.taskType == "5") {
          if (getters.hasTaskDailyRedDot) {
            res = true;
            break;
          }
        } else {
          if (element.canClaim) {
            res = true;
            break;
          }
        }
      }

      return res;
    },
  },
  actions: {
    toActivityDetail(store, id: string) {
      if (!id) return;
      const activity = store.state.activityList.filter(
        (v: ActivityListItem) => v.id == id
      )[0];
      if (!activity) return;
      // giftType 1跳充值活动 2跳打码活动 3跳救济金 4跳自定义活动 5代理宝箱
      if (activity?.giftType == 1 || activity?.giftType == 2) {
        useCustomRouter(null).push({
          path: "/activity/purchaseOrBet",
          query: {
            categoryId: activity.categoryId,
            description: activity.description,
            finishTime: activity.finishTime,
            giftType: activity.giftType,
            id: activity.id,
            img: activity.img,
            name: activity.name,
            startTime: activity.startTime,
          },
        });
        return;
      }
      if (activity?.giftType == 3) {
        useCustomRouter(null).push({
          path: "/activity/aims",
          query: {
            categoryId: activity.categoryId,
            // description: activity.description,
            // finishTime: activity.finishTime,
            id: activity.id,
            img: activity.img ? activity.img : "",
            // name: activity.name,
            // startTime: activity.startTime,
          },
        });
        return;
      }
      if (activity.giftType == 4) {
        useCustomRouter(null).push({
          name: "ActivityInfoCustom",
          params: {
            categoryId: activity.categoryId,
            id: activity.id,
            img: activity.img ? activity.img : "",
          },
        });
      }
      if (activity.giftType == 5) {
        useCustomRouter(null).push({
          name: "ActivityAgentTreaSureChest",
          query: {
            categoryId: activity.categoryId,
            description: activity.description,
            finishTime: activity.finishTime,
            id: activity.id,
            name: activity.name,
            startTime: activity.startTime,
          },
        });
      }
    },
    requestActivityPage({ dispatch, rootGetters }) {
      activityLogic.getActivityList("0").then((response) => {
        rootGetters.loginSuccess &&
          response?.info &&
          response.info.forEach((v: any) => {
            dispatch("handleRedDot", v);
          });
      });
    },
    handleRedDot({ commit }, v: any) {
      if (v.id == "0") {
        if (v.discountType == "13") {
          activityLogic.getActivityInfoSecretBonus();
        } else if (v.discountType == "6") {
          // 红包雨红点
          store.dispatch("redpack/getRedpackDocs", { redDot: true });
        }
      } else {
        if (
          v.giftType == 1 ||
          v.giftType == 2 ||
          v.giftType == 3 ||
          v.giftType == 5
        ) {
          // 1跳充值活动 2跳打码活动 3救援金 5代理宝箱
          activityLogic.getActivityInfo(
            Number(v.categoryId),
            Number(v.id),
            (infoList: ActivityInfo[]) => {
              const canClaim = infoList.some((value) => value.claimed == 1);
              const key = `${v.id}-${v.categoryId}-${v.giftType}-${v.discountType}`;
              commit("saveRedDot", { key, res: canClaim });
            }
          );
        }
      }
    },
  },
  mutations: {
    saveActivityList(state, payload) {
      const tempArray = cloneDeep(state.activityList);
      payload.list?.forEach((v: any) => {
        const t = tempArray.find(
          (item) =>
            `${item.id}-${item.categoryId}-${item.giftType}-${item.discountType}` ==
            `${v.id}-${v.categoryId}-${v.giftType}-${v.discountType}`
        );
        if (t) {
          v.canClaim = t.canClaim;
        }
      });

      state.activityList = payload.list;
      state.allClaimActivity = payload.all;
    },
    // saveleftActList(state, payload) {
    //   state.leftActList = payload.list;
    // },
    saveAtivoBoxInfo(state, payload) {
      state.ativoBoxInfo = payload.data;
    },
    saveTaskActList(state, payload) {
      const tempArray = cloneDeep(state.taskActList);
      payload.list?.forEach((v: any) => {
        const t = tempArray.find((item) => item.taskType == v.taskType);
        if (t) {
          v.canClaim = t.canClaim;
        }
      });

      state.taskActList = payload.list;
      state.allChallenge = payload.all;
    },
    saveRedDot(state, payload) {
      const activity = state.activityList.find(
        (v) =>
          `${v.id}-${v.categoryId}-${v.giftType}-${v.discountType}` ==
          payload.key
      );
      if (activity) {
        activity.canClaim = payload.res;
      }
    },
    saveClaimSecretIds(state, payload) {
      state.claimSecretIds = payload;
    },
    saveTaskData(state, payload) {
      if (!state.taskData) {
        state.taskData = new Map();
      }

      state.taskData.set(payload.key, payload.res);
    },
    saveTaskRedDot(state, payload) {
      const taskTab = state.taskActList.find((v) => v.taskType === payload.key);
      if (taskTab) {
        taskTab.canClaim = payload.res;
      }
    },
    saveDailyCheckInStatus(state, payload) {
      state.dailyCheckInStatus = payload;
    },
    saveDailyCanClaim(state, payload) {
      state.dailyCanClaim = payload;
    },
  },
};
