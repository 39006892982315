import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import * as PATHS from "@/router/paths";

const HomeView = () => import("@/views/HomeView.vue");
const VipView = () => import("@/views/VipView.vue");
const TaskView = () => import("@/views/TaskView.vue");
const GameHall = () => import("@/views/GameHall.vue");
const DiscountHallView = () => import("@/views/DiscountHallView.vue");
//------My------//
const MyView = () => import("@/views/MyView.vue");
const MySecurityPage = () => import("@/components/my/MySecurityPage.vue");
const AboutUsView = () => import("@/components/my/AboutUs.vue");
const UserInfoPage = () => import("@/components/my/UserInfoPage.vue");
const UserInfoPageEdit = () => import("@/components/my/UserInfoPageEdit.vue");

//------代理------//
const AgentView = () => import("@/views/AgentView.vue");
const AgentMember = () => import("@/components/agent/AgentMemberList.vue");
const AgentCommission = () =>
  import("@/components/agent/AgentComissionList.vue");
const AgentCommissionDetail = () =>
  import("@/components/agent/CommissionDetailNB.vue");

//------活动------//
const ActivityView = () => import("@/views/ActivityView.vue");
const ActivityInfoCustom = () =>
  import("@/components/activity/ActivityInfoCustom.vue");
const ActivityInfoAims = () =>
  import("@/components/activity/ActivityInfoAims.vue");
const ActivityPurchaseOrBet = () =>
  import("@/components/activity/ActivityPurchaseOrBet.vue");
const ActivityPrizeWheel = () =>
  import("@/components/activity/ActivityPrizeWheel.vue");
const ActivityAgentTreaSureChest = () =>
  import("@/components/activity/ActivityAgentTreaSureChest.vue");
const ActivityMysteryBonus = () =>
  import("@/components/activity/ActivityMysteryBonus.vue");
const ScoreRule = () => import("@/components/task/ScoreRule.vue");
//------提取------//
const WithdrawView = () => import("@/views/WithdrawView.vue");
const WithdrawRecord = () => import("@/components/shop/WithdrawRecord.vue");
const WithdrawDetail = () => import("@/components/shop/WithdrawDetail.vue");
const ChipStatements = () => import("@/components/shop/ChipStatements.vue");
//-----记录------//
const CashAccount = () => import("@/components/my/CashAccountTable.vue");
const UserAccountView = () => import("@/views/UserAccountView.vue");
const BetCasinoRecord = () =>
  import("@/components/my/BetCasinoRecordTable.vue");
const BetSportsRecord = () =>
  import("@/components/my/BetSportsRecordTable.vue");
const BetLotteryRecord = () =>
  import("@/components/my/BetLotteryRecordTable.vue");
const BetSportsRecordDetail = () =>
  import("@/components/my/BetSportsRecordDetail.vue");
const BetLotteryRecordDetail = () =>
  import("@/components/my/BetLotteryRecordDetail.vue");

// const PlayViewForPC = () => import("@/views/PlayViewForPC.vue");
// const PlayViewForQK = () => import("@/views/PlayViewForQK.vue");

const FAQVIEW = () => import("@/views/FAQ.vue");
const TransferView = () => import("@/views/TransferView.vue");
const TransferDetailView = () => import("@/views/TransferDetailView.vue");

const LoginView = () => import("@/views/LoginView.vue");
const RegisterView = () => import("@/views/RegisterView.vue");
const RegisterThirdView = () => import("@/views/RegisterThirdView.vue");
const ForgetPasswordView = () => import("@/views/ForgetPasswordView.vue");

//----- 消息------//
const NBUserMessage = () => import("@/components/home/NBUserMessage.vue");
const MessageList = () => import("@/components/message/MessageList.vue");
const AnnouncementList = () =>
  import("@/components/message/AnnouncementList.vue");
const NBUserMessageDetail = () =>
  import("@/components/home/NBUserMessageDetail.vue");

const ShopView = () => import("@/views/ShopView.vue");
const RechargeRecord = () => import("@/components/shop/RechargeRecord.vue");
const RechargeDetail = () => import("@/components/shop/RechargeDetail.vue");

//----- 返利------//
const RebateView = () => import("@/views/RebateView.vue");
const RebateRecord = () => import("@/components/rebate/RebateRecord.vue");
const RebateDetail = () => import("@/components/rebate/RebateDetail.vue");
const RebateRatio = () => import("@/components/rebate/RebateRatio.vue");
//----- 返利------//
const BonusDrawRecord = () => import("@/components/task/BonusDrawRecord.vue");
import { store } from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATHS.ROUTE_LOGIN.path,
    name: PATHS.ROUTE_LOGIN.name,
    component: LoginView,
    meta: { depth: 1100 },
  },
  {
    path: PATHS.ROUTE_REGISTER.path,
    name: PATHS.ROUTE_REGISTER.name,
    component: RegisterView,
    meta: { depth: 1101 },
  },
  {
    path: PATHS.ROUTE_REGISTER_THIRD.path,
    name: PATHS.ROUTE_REGISTER_THIRD.name,
    component: RegisterThirdView,
    meta: { depth: 1102 },
  },
  {
    path: "/forgetPassword",
    name: "ForgetPassword",
    component: ForgetPasswordView,
    meta: { depth: 1101 },
  },
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: { depth: 0, needKeep: true },
  },
  {
    path: PATHS.ROUTE_VIP.path,
    name: PATHS.ROUTE_VIP.name,
    component: VipView,
    meta: { requiresAuth: true, depth: 10, unsupportGuest: true },
  },
  {
    path: "/bonusDrawRecord",
    name: "BonusDrawRecord",
    component: BonusDrawRecord,
    meta: { requiresAuth: true, depth: 12 },
  },
  {
    path: "/invite",
    name: "Agent Invite",
    component: AgentView,
    meta: { depth: 900 },
  },
  {
    path: "/invite/member",
    name: "AgentMember",
    component: AgentMember,
    meta: { requiresAuth: true, depth: 901 },
  },
  {
    path: "/invite/commission",
    name: "AgentCommission",
    component: AgentCommission,
    meta: { requiresAuth: true, depth: 901 },
  },
  {
    path: "/invite/commission/detail/:commissionId",
    name: "AgentCommissionDetail",
    component: AgentCommissionDetail,
    meta: { requiresAuth: true, depth: 902 },
  },
  {
    path: "/my",
    name: "My",
    component: MyView,
    meta: { depth: 0 },
  },
  {
    path: "/my/safety",
    name: "MySecurityPage",
    component: MySecurityPage,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 10 },
  },
  {
    path: "/my/aboutUs",
    name: "AboutUs",
    component: AboutUsView,
    meta: { depth: 10 },
  },
  {
    path: PATHS.ROUTE_USER_INFO.path,
    name: PATHS.ROUTE_USER_INFO.name,
    component: UserInfoPage,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 10 },
  },
  {
    path: PATHS.ROUTE_USER_INFO_EDIT.path,
    name: PATHS.ROUTE_USER_INFO_EDIT.name,
    component: UserInfoPageEdit,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 11 },
  },
  {
    path: "/discountHall",
    name: "DiscountHallView",
    component: DiscountHallView,
    redirect: "/discountHall/activity",
    meta: { depth: 10, needKeep: true },
    children: [
      {
        path: "activity",
        name: "ActivityView",
        component: ActivityView,
        meta: { depth: 11, needKeep: true },
      },
      {
        path: "task",
        name: "TaskView",
        component: TaskView,
        meta: { depth: 11 },
      },
      {
        path: "rebate",
        name: "RebateView",
        component: RebateView,
        meta: { depth: 11 },
      },
    ],
  },
  {
    path: PATHS.ROUTE_SCORERULE.path,
    name: PATHS.ROUTE_SCORERULE.name,
    component: ScoreRule,
    meta: { requiresAuth: true, depth: 13 },
  },
  {
    path: PATHS.ROUTE_REBATE_RECORD.path,
    name: PATHS.ROUTE_REBATE_RECORD.name,
    component: RebateRecord,
    meta: { requiresAuth: true, depth: 12 },
  },
  {
    path: "/rebateDetail/:time/:type",
    name: "RebateDetail",
    component: RebateDetail,
    meta: { depth: 13 },
  },
  {
    path: PATHS.ROUTE_REBATE_RATIO.path,
    name: PATHS.ROUTE_REBATE_RATIO.name,
    component: RebateRatio,
    meta: { requiresAuth: true, depth: 12 },
  },
  {
    path: "/activity/CustomDetail/:categoryId/:id/:img?",
    name: "ActivityInfoCustom",
    component: ActivityInfoCustom,
    meta: { depth: 12 },
  },
  {
    path: "/activity/aims",
    name: "ActivityInfoAims",
    component: ActivityInfoAims,
    meta: { depth: 12 },
  },
  {
    path: "/activity/purchaseOrBet",
    name: "PurchaseOrBet",
    component: ActivityPurchaseOrBet,
    meta: { depth: 12 },
  },
  {
    path: "/activity/prizeWheel",
    name: "ActivityPrizeWheel",
    component: ActivityPrizeWheel,
    meta: { depth: 12 },
  },
  {
    path: "/activity/agentTreaSureChest",
    name: "ActivityAgentTreaSureChest",
    component: ActivityAgentTreaSureChest,
    meta: { depth: 12 },
  },
  {
    path: "/activity/mysteryBonus",
    name: "ActivityMysteryBonus",
    component: ActivityMysteryBonus,
    meta: { depth: 12 },
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: WithdrawView,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 10 },
  },
  {
    path: "/withdrawRecord",
    name: "WithdrawRecord",
    component: WithdrawRecord,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 11 },
  },
  {
    path: "/withdrawDetail",
    name: "WithdrawDetail",
    component: WithdrawDetail,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 12 },
  },
  {
    path: "/withdrawAudit",
    name: "ChipStatements",
    component: ChipStatements,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 11 },
  },
  {
    path: "/message",
    name: "Message",
    component: NBUserMessage,
    redirect: "/message/list/1",
    meta: { depth: 10 },
    children: [
      {
        path: "list/:type",
        component: MessageList,
        meta: { depth: 11 },
      },
      {
        path: "announcement",
        component: AnnouncementList,
        meta: { depth: 11 },
      },
    ],
  },
  {
    path: "/message/detail/:type/:messageId/",
    name: "MessageDetail",
    component: NBUserMessageDetail,
    meta: { depth: 12 },
  },
  {
    path: PATHS.ROUTE_SHOP.path,
    name: PATHS.ROUTE_SHOP.name,
    component: ShopView,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 1000 }, // 充值页面depth设置大一点，一些活动页面也会跳转
  },
  {
    path: "/rechargeRecord",
    name: "RechargeRecord",
    component: RechargeRecord,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 1001 },
  },
  {
    path: "/rechargeDetail",
    name: "RechargeDetail",
    component: RechargeDetail,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 1002 },
  },
  {
    path: PATHS.ROUTE_CASH.path,
    name: PATHS.ROUTE_CASH.name,
    component: CashAccount,
    meta: { requiresAuth: true, depth: 20 },
  },
  {
    path: "/betOrder",
    name: "betOrder",
    component: UserAccountView,
    redirect: "/betOrder/sports",
    meta: { depth: 10 },
    children: [
      {
        path: "casino",
        name: "casino",
        component: BetCasinoRecord,
        meta: { requiresAuth: true, depth: 11 },
      },
      {
        path: "sports",
        name: "sports",
        component: BetSportsRecord,
        meta: { requiresAuth: true, depth: 11 },
      },
      {
        path: "lottery",
        name: "lottery",
        component: BetLotteryRecord,
        meta: { requiresAuth: true, depth: 11 },
      },
    ],
  },
  {
    path: "/betOrder/sports/detail",
    name: "sportsDetail",
    component: BetSportsRecordDetail,
    meta: { requiresAuth: true, depth: 12 },
  },
  {
    path: "/betOrder/lottery/detail",
    name: "lotteryDetail",
    component: BetLotteryRecordDetail,
    meta: { requiresAuth: true, depth: 12 },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQVIEW,
    meta: { depth: 10 },
  },
  {
    path: "/transfer",
    name: "TransferView",
    component: TransferView,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 30 },
  },
  {
    path: "/transfer/detail",
    name: "TransferDetail",
    component: TransferDetailView,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 31 },
  },
  {
    path: "/gameHall",
    name: "GameHall",
    component: GameHall,
    meta: { depth: 20 },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const scrollMap = new Map<string, number>();
export const clearScrollMap = () => {
  // 登录或者退出账号时，清空
  scrollMap.clear();
};

import { useCustomRouter, useRouterAnimate } from "@/router/custRouter";
import { showNBGuestLimitDialog } from "@/utils/dialog";
import { isNumeric } from "@/utils/util";
import {
  AGENT_CODE,
  APP_IDS,
  APPSFLEER_ID,
  CHANNEL_CODE,
  FB_CLID,
} from "@/utils/consts";
const needAnimate = useRouterAnimate();
useCustomRouter(router);

router.beforeEach(async (to, from, next) => {
  rememberScrollTop(to, from);
  if (
    (to.path === PATHS.ROUTE_LOGIN.path ||
      to.path === PATHS.ROUTE_REGISTER.path ||
      to.path === "/registerOther") &&
    store.getters.userType.isGuest
  ) {
    // 游客模式进入登录注册带上role=guest
    to.query.role = "guest";
  }

  if (loginRedirect(to, from, next)) {
    // next(false);
  } else if (
    store.getters.loginSuccess &&
    store.getters.userType.isGuest &&
    to.meta.unsupportGuest
  ) {
    // 登录状态下，登录模式为游客。禁止进入的页面
    showNBGuestLimitDialog();
    next(false);
  } else {
    handleActionAfterLogin(to, from);
    routeAnimate(to, from, next);
  }
});

router.beforeResolve(async (to) => {
  if (to.path === "/") {
    //回到首页时拿取弹窗
    store.commit("messagePopupBox/popMessage");
  }
});

const routeAnimate = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const toDepth = to.meta.depth;
  const fromDepth = from.meta.depth;

  if ((toDepth === 0 && fromDepth == void 0) || toDepth === fromDepth) {
    console.log(3333, toDepth, fromDepth);
    goRoute(to, from, next);
    return;
  }
  if (!from.path) {
    console.log(3334, toDepth, fromDepth);
    goRoute(to, from, next);
    return;
  }
  if (!needAnimate.value) {
    console.log(3335, toDepth, fromDepth);
    goRoute(to, from, next);
    return;
  }

  console.log(3336, toDepth, fromDepth, to, from);
  to.meta.transitionName =
    Number(toDepth) < Number(fromDepth) ? "slide-right" : "slide-left";
  goRoute(to, from, next);
};

const goRoute = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const initialParams = from.query;
  const hasAgentCode = Object.prototype.hasOwnProperty.call(
    initialParams,
    AGENT_CODE
  );
  const hasChannelCode = Object.prototype.hasOwnProperty.call(
    initialParams,
    CHANNEL_CODE
  );
  const hasAppsFlyerId = Object.prototype.hasOwnProperty.call(
    initialParams,
    APPSFLEER_ID
  );
  const hasAppIds = Object.prototype.hasOwnProperty.call(
    initialParams,
    APP_IDS
  );
  const hasFbclid = Object.prototype.hasOwnProperty.call(
    initialParams,
    FB_CLID
  );

  const toParams = to.query;
  const toHasAgentCode = Object.prototype.hasOwnProperty.call(
    toParams,
    AGENT_CODE
  );
  const toHasChannelCode = Object.prototype.hasOwnProperty.call(
    toParams,
    CHANNEL_CODE
  );
  const toHasAppsFlyerId = Object.prototype.hasOwnProperty.call(
    toParams,
    APPSFLEER_ID
  );
  const toHasAppIds = Object.prototype.hasOwnProperty.call(toParams, APP_IDS);
  const toHasFbclid = Object.prototype.hasOwnProperty.call(toParams, FB_CLID);

  if (
    (!toHasAgentCode && hasAgentCode) ||
    (!toHasChannelCode && hasChannelCode) ||
    (!toHasAppsFlyerId && hasAppsFlyerId) ||
    (!toHasAppIds && hasAppIds) ||
    (!toHasFbclid && hasFbclid)
  ) {
    const params = {} as any;
    // if (hasfbid) {
    //   params.fbid = initialParams.fbid;
    // }
    if (hasAgentCode) {
      params[AGENT_CODE] = initialParams[AGENT_CODE];
    }
    if (hasChannelCode) {
      params[CHANNEL_CODE] = initialParams[CHANNEL_CODE];
    }
    if (hasAppsFlyerId) {
      params[APPSFLEER_ID] = initialParams[APPSFLEER_ID];
    }
    if (hasAppIds) {
      params[APP_IDS] = initialParams[APP_IDS];
    }
    if (hasFbclid) {
      params[FB_CLID] = initialParams[FB_CLID];
    }

    console.log(5555555);
    next({ ...to, query: { ...to.query, ...params } });
  } else {
    next();
  }
};

const rememberScrollTop = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  if (isNumeric(from.meta.scrollTop)) {
    // 离开的页面需要记住当前滑动高度
    scrollMap.set(from.path, Number(from.meta.scrollTop));
  }

  if (scrollMap.has(to.path)) {
    // 即将进入的页面之前有记住滑动高度
    const scrollTop = scrollMap.get(to.path);
    to.meta.scrollTop = scrollTop;
    // 清除数据
    scrollMap.delete(to.path);
  } else {
    // 清除to的scrollTop，避免有缓存
    to.meta.scrollTop = null;
  }
};

const redirectSupport = new Set([
  PATHS.ROUTE_VIP.path,
  PATHS.ROUTE_SHOP.path,
  PATHS.ROUTE_CASH.path,
  PATHS.ROUTE_SCORERULE.path,
  PATHS.ROUTE_TASK_DRAW_RECORD.path,
  PATHS.ROUTE_REBATE_RECORD.path,
  PATHS.ROUTE_REBATE_RATIO.path,
]);

const loginRedirect = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const requiresAuth = to.meta.requiresAuth && !store.getters.loginSuccess;
  if (requiresAuth) {
    needAnimate.value = true; // 如果是replace，就不会在useCustomRouter自动设置
    if (redirectSupport.has(to.path)) {
      console.log(66666666);
      next({
        path: PATHS.ROUTE_LOGIN.path,
        query: { redirect: to.fullPath },
        replace: false,
      });
      // customRouter.push({ path: "/login", query: { redirect: to.fullPath }});
    } else {
      next({ path: PATHS.ROUTE_LOGIN.path, replace: false });
      // customRouter.push({ path: "/login" });
    }
  }

  return requiresAuth;
};
// 处理登录成功back路由后，需要继续的事件
const handleActionAfterLogin = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  if (
    store.getters.loginSuccess &&
    from.query.redirect === "back" &&
    to.path !== PATHS.ROUTE_LOGIN.path &&
    to.path !== PATHS.ROUTE_REGISTER.path &&
    (from.path === PATHS.ROUTE_LOGIN.path ||
      from.path === PATHS.ROUTE_REGISTER.path)
  ) {
    // 已登录 && 重定向是back && 从登录或注册页 返回 非登录注册页面
    if (from.query.action) {
      //存在action事件
      console.warn("插入事件", from.query.action);
      to.meta.action = from.query.action;
    }
  }
};

router.afterEach((to, from) => {
  console.log("afterEach", to, from);
  needAnimate.value = false;
  // const requiresAuth = to.meta.requiresAuth && !store.getters.loginSuccess;
  // if (!requiresAuth) {
  //   needAnimate.value = false;
  // }
});

export default router;
